import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'David Jian | Full Stack Developer',
  lang: 'en',
  description: 'Checkout my amazing site!'
};

// HERO DATA
export const heroData = {
  title: 'Welcome, my name is',
  name: 'David Jian',
  subtitle: 'I\'m the Fullstack Developer & Tech Advocate.',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.png',
  paragraphOne: '',
  paragraphTwo: '',
  paragraphThree: '',
  resume: 'https://www.resumemaker.online/es.php', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'rails.png',
    title: 'Ruby on Rails',
    info: 'The very first Framework I used to develop website is Ruby on Rails. I got to know if following the Odin project course, and I haven\' found another framework that does it job as well as Ruby in term of delivering a feature in a fast and reliable way.',
    info2: 'I started Rails Development in late September 2017, and have worked on multiple projects since then. The longest and largest projects I worked on is the Crypto Exchange and I learnt most from it.',
    url: 'https://www.test.com',
    repo: 'https://github.co/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'react.png',
    title: 'React + React Native',
    info: 'I got into React Native first before React. Mobile development have been tough using React Native back in early 2018, and I stopped right there and then in 2019 with React Native. React on the other hand was fun and exciting to quickly build beautiful site with component library like AntDesign. It speed up my development on the front end with beautiful interactive site compare to Rails ERB.',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'flutter.png',
    title: 'Flutter',
    info: 'Flutter is a performance improved React Native, using typed Dart language, it`s absolutely faster. I have yet to build a production APP with Flutter since I just learn it recently',
    info2: '',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'aws.png',
    title: 'AWS Deployment',
    info: 'I have been practicing on AWS since I started to work on Cryptocurrency Exchanges projects. I am still utilising it today for multiple website.',
    info2: 'AWS have wide array of services. So to narrow down, my experience focus on EC2, S3, Elastic Beanstalk, ELB, Scaling Group, ACM, CloudFront CDN. The one I use more frequent now is Elastic Beanstalk.',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'blockchain.png',
    title: 'Blockchain Technology',
    info: 'As mentioned, I had my time within the crypto space since I was developing an exchange as well as mobile wallet. I had launched a few smart contract written in Solidity on Ethereum as well as interacting with web3.',
    info2: 'Most of the time spent as a Blockchain Developer is with understanding how blockchain work and how to communicate with them using RPC call. And the rest of the time is spent writing smart contract. I can\'t say that I\'m the expert in this field yet, but definitely am striving to be.',
    url: '',
    repo: 'https://github.com/cobidev/react-simplefolio', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'davidjian0905@gmail.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/jianesis',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/david-jian-018654108/',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/jianesis',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: true, // set to false to disable the GitHub stars/fork buttons
};
